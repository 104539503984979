import { Observable } from 'rxjs';

export function blobToJSON(blob: any): Observable<any> {
  return new Observable<string>((observer: any) => {
    if (!(blob instanceof Blob)) {
      observer.next('');
      observer.complete();
    } else {
      const reader = new FileReader();
      reader.onload = event => {
        const text = (<any>event.target).result;
        observer.next(text ? JSON.parse(text) : null);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}

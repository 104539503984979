import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ContentChildren,
  AfterContentChecked,
  OnDestroy,
  AfterContentInit,
  EventEmitter,
  Output,
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'prada-tab-list',
  templateUrl: './tab-list.component.html',
  styleUrls: ['./tab-list.component.scss'],
})
export class TabListComponent implements OnInit, AfterContentInit {
  @ContentChildren(TabComponent, { descendants: true }) tabs: QueryList<TabComponent>;
  @Output() change = new EventEmitter<number>();
  constructor() {}

  ngOnInit() {}

  ngAfterContentInit() {
    const activeTabs = this.tabs.filter(tab => tab.active);

    if (activeTabs.length === 0 && this.tabs.length) {
      this.onSetTab(this.tabs.first);
    }
  }

  onSetTab(tab: TabComponent) {
    this.tabs.forEach(t => (t.active = false));
    tab.active = true;
    this.change.emit(this.tabs.toArray().indexOf(tab));
  }
}

<div class="d-flex align-items-center">
  <label class="switch mr-2">
    <input type="checkbox"
      #input
      [checked]="value"
      [disabled]="disabled"
      (change)="onSwitch(input.checked)">
    <span class="switch--slider"
      [ngClass]="{'disabled': disabled}"></span>
  </label>
</div>
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PortalModule } from '@angular/cdk/portal';

import { SharedModule } from '@shared/shared.module';
import { CommonCoreModule } from '@common/core/common-core.module';

import { throwIfAlreadyLoaded } from './module-config';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { DesktopLayoutComponent } from './components/desktop-layout/desktop-layout.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { SidenavMenuComponent } from './components/sidenav-menu/sidenav-menu.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignOutComponent } from './components/sign-out/sign-out.component';
import { SignInErrorComponent } from './components/sign-in-error/sign-in-error.component';

const EXPORTS_COMPONENTS = [NotFoundComponent, SignInComponent];
const COMPONENTS = [DesktopLayoutComponent, SidenavMenuComponent, MainMenuComponent, SignOutComponent, SignInErrorComponent];
@NgModule({
  imports: [CommonCoreModule, RouterModule, SharedModule.forRoot(), PortalModule],
  exports: [...EXPORTS_COMPONENTS],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  providers: [],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

import { Directive, Input, OnInit, Renderer2, ViewContainerRef, TemplateRef } from '@angular/core';

import { UiRoleManagerService } from '@services';
import { ERoleActions, ERoleMode } from '@enums';

export interface IBitfRoleManagerConfig {
  action?: ERoleActions;
  mode?: ERoleMode;
  reverse?: boolean;
}

@Directive({
  selector: '[bitfUiRoleManager]',
})
export class BitfUiRoleManagerDirective implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('bitfUiRoleManager')
  config: IBitfRoleManagerConfig = {};
  nativeElement: HTMLElement;

  constructor(
    private viewRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private renderer: Renderer2,
    private uiRoleManagerService: UiRoleManagerService
  ) {}

  ngOnInit(): void {
    this.config = this.config || {};
    if (!this.config || !this.config.action) {
      this.createComponent();
      return;
    }

    if (!this.config.mode) {
      this.config.mode = ERoleMode.HIDDEN;
    }
    this.mutateView();
  }

  private mutateView() {
    const canI = this.config.reverse
      ? !this.uiRoleManagerService.canI(this.config.action)
      : this.uiRoleManagerService.canI(this.config.action);

    switch (this.config.mode) {
      case ERoleMode.HIDDEN:
        if (canI) {
          this.createEmbeddedView();
          this.setVisibility('visible');
        }
        break;
      case ERoleMode.DISABLED:
        this.createEmbeddedView();
        if (!this.isNativeElementReal()) {
          return;
        }
        if (!canI) {
          setTimeout(() => {
            this.renderer.setAttribute(this.nativeElement, 'disabled', 'true');
            this.setVisibility('visible');
          }, 0);
        } else {
          this.setVisibility('visible');
        }
        break;
      case ERoleMode.READ_ONLY:
        this.createEmbeddedView();
        if (!this.isNativeElementReal()) {
          return;
        }
        if (!canI) {
          setTimeout(() => {
            this.renderer.setAttribute(this.nativeElement, 'readonly', 'true');
            this.setVisibility('visible');
          }, 0);
        } else {
          this.setVisibility('visible');
        }
        break;
    }
  }

  private createEmbeddedView() {
    const viewContainerRef: any = this.createComponent();
    this.nativeElement = viewContainerRef.rootNodes[0];
    if (this.isNativeElementReal()) {
      this.renderer.addClass(this.nativeElement, 'uiRolesManager');
      this.setVisibility('hidden');
    }
  }

  private setVisibility(state: string) {
    if (this.isNativeElementReal()) {
      this.renderer.setStyle(this.nativeElement, 'visibility', state);
    }
  }

  private createComponent(): any {
    return this.viewRef.createEmbeddedView(this.templateRef, {});
  }

  private isNativeElementReal(): boolean {
    return this.nativeElement.innerHTML !== undefined;
  }
}

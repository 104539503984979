import { switchMap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { IBitfApiResponse } from '@interfaces';
import { User } from '@models';
import { environment } from '@env/environment';
import { ConfigService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BitfApiService {
  constructor(public injector: Injector,
    private configService: ConfigService) {
    super('users', injector);
    this.configService = configService;
  }

  getMe(): Observable<IBitfApiResponse<User>> {
    return this.configService.pullConfiguration(environment.currentEventCode).pipe(
      switchMap(() => super.request<User>({
        path: '/me',
        method: 'GET',
        disableHideLoader: true,
      }))
    );
  }
}

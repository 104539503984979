<div class="trigger"
  (click)="open(dropdown, origin)"
  #origin>
  <div class="text-truncate">{{currentTextValue}}</div>
  <i id="open"
    class="material-icons ml-auto icon-s"
    *ngIf="isOpen">keyboard_arrow_up</i>
  <i id="close"
    class="material-icons ml-auto icon-s"
    *ngIf="!isOpen">keyboard_arrow_down</i>
</div>
<ng-template #dropdown>
  <div class="menu">
    <div class="search-input"
      *ngIf="searchable"
      (click)="$event.stopPropagation()">
      <input type="text"
        pradaAutofocus
        [formControl]="search"
        placeholder="{{'COMMON.LABEL.SEARCH' | translate}}" />
      <i class="material-icons">search</i>
    </div>
    <div *ngIf="!options.length && originalOptions.length"
      class="no-results-item">{{'COMMON.LABEL.NO_RESULTS_FOUND' | translate}}</div>
    <cdk-virtual-scroll-viewport itemSize="32"
      [style.height.px]="options.length > visibleOptions ? visibleOptions * 32 : options?.length * 32">
      <div *cdkVirtualFor="let option of options"
        class="item"
        (click)="select($event, option)">
        <div class="d-flex align-items-center">
          <mat-checkbox *ngIf="multiple"
            (click)="$event.preventDefault()"
            [checked]="value?.includes(option[key])"
            class="mr-2"></mat-checkbox>
          <div>{{option[label]}}</div>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-template>
import { Injectable, Injector } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { initDynamicLayoutUrl, updateAppLayout } from '@bitf/utils/bitf-responsive.utils';

import { BitfAppSessionService } from '@bitf/services/app-session/bitf-app-session.service';
import { BitfTagManagerService } from '@bitf/services/tag-manager/bitf-tag-manager.service';
import { BitfLogDnaSenderService } from '@bitf/services/logger/bitf-log-dna-sender.service';

import { ResponsiveService } from '@services';
import { Store } from '@models';
import { EStoreActions } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class AppSessionService extends BitfAppSessionService {
  activeBreakpoints = {
    isDesktop: false,
    isMobile: false,
  };

  constructor(
    private bitfTagManagerService: BitfTagManagerService,
    private bitfLogDnaSenderService: BitfLogDnaSenderService,
    private responsiveService: ResponsiveService,
    private breakpointObserver: BreakpointObserver,
    protected injector: Injector
  ) {
    super(injector);
  }

  async init() {
    await super.init();

    // NOTE: needed for mobile/desktop rounting support
    // initDynamicLayoutUrl(this.router, this.storeService);
    this.initBreakpointListener();
    this.responsiveService.init();
    // --------------------------

    this.bitfTagManagerService.init();
    this.bitfLogDnaSenderService.init();
  }

  // FIXME
  initBreakpointListener() {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe(result => {
        console.log(result.matches);
        if (result.matches) {
          this.storeService.setStore((store: Store) => {
            store.activeBreakpoints.isDesktop = true;
            store.activeBreakpoints.isMobile = false;
          }, EStoreActions.BREACKPOINT);
        } else {
          this.storeService.setStore((store: Store) => {
            store.activeBreakpoints.isDesktop = false;
            store.activeBreakpoints.isMobile = true;
          }, EStoreActions.BREACKPOINT);
        }
        // updateAppLayout(this.router, result.matches);
      });
  }

  initLogSender() {
    console.log('initLogSender, Implement me');
  }
}

import { Component, OnInit } from '@angular/core';

import { IBitfMenuItem, IBitfLanguage } from '@interfaces';
import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';
import { APP_VERSION, BUILD_DATE } from '@env/version';
import { AppSessionService, UiRoleManagerService, AuthService, StoreService } from '@services';
import { ERoleActions, EBitfAuthState } from '@web/enums';
import { CONSTANTS } from '@web/constants';
import { FormControl } from '@angular/forms';
import { BitfDynamicLocaleService } from '@common/libs/bitforce/core/services/locale/bitf-dynamic-locale.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'prada-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  appVersion = APP_VERSION;
  appBuildAt = BUILD_DATE;

  languages = CONSTANTS.SUPPORTED_LANGUAGES;
  language: string;
  menuItems: Array<IBitfMenuItem> = [];

  isOpen = false;

  constructor(
    public appSessionService: AppSessionService,
    private uiRoleManagerService: UiRoleManagerService,
    private bitfDynamicLocaleService: BitfDynamicLocaleService,
    private authService: AuthService,
    private storeService: StoreService
  ) {}

  ngOnInit() {
    this.language = this.bitfDynamicLocaleService.locale.code.toUpperCase();
    this.authService.authState$.subscribe((authState: EBitfAuthState) => {
      if (authState === EBitfAuthState.LOGGED) {
        this.addMenuItems();
      }
    });
    if (this.storeService.store.user) {
      this.addMenuItems();
    }
  }

  private addMenuItems() {
    this.menuItems = [];
  }

  onLanguageChange(lang: IBitfLanguage) {
    this.language = lang.code;
    this.bitfDynamicLocaleService.setLocale(lang);
  }

  toggleSidenav(status?: boolean) {
    if (status) {
      this.isOpen = status;
    } else {
      this.isOpen = !this.isOpen;
    }
    window.dispatchEvent(new Event('resize'));
  }
}

<mat-toolbar class="justify-content-between align-items-center prada-white-bg pl-0 pr-3 fixed-top">
  <div class="d-flex align-items-center w-100">
    <!-- <div class="d-block d-sm-none">
      <button mat-icon-button
        (click)="openMainMenu()">
        <mat-icon>menu</mat-icon>
      </button>
    </div> -->

    <div class="col-auto brand-logo d-flex align-items-center">
      <img class="logo cursor-pointer"
        (click)="onGoToHome()"
        src="assets/images/logo_prada_group.png" />
    </div>
    <ng-content></ng-content>
    <div class="ml-auto mr-2 d-flex align-items-center min-width-0">
      <div class="user-section user-section--clickable"
        *bitfUiRoleManager="isMeetUserRoleConfig"
        #userSectionTriggerMenu="matMenuTrigger"
        [matMenuTriggerFor]="userMenu">
        <fa-icon icon="user-circle"
          class="icon-m mr-2"></fa-icon>
        <div class="user">
          {{storeService?.store?.user?.completeName}}
        </div>
        <fa-icon icon="chevron-up"
          class="ml-2 icon-xs"
          *ngIf="userSectionTriggerMenu.menuOpen"></fa-icon>
        <fa-icon icon="chevron-down"
          class="ml-2 icon-xs"
          *ngIf="!userSectionTriggerMenu.menuOpen"></fa-icon>
      </div>

      <div class="user-section"
        *bitfUiRoleManager="isMeetUserReverseRoleConfig">
        <fa-icon icon="user-circle"
          class="icon-m mr-2"></fa-icon>
        <div class="user">
          {{storeService?.store?.user?.completeName}}
        </div>
      </div>

      <fa-icon *ngIf="programType === 'BK'"
        icon="question-circle"
        class="ml-3 icon-m cursor-pointer"
        pradaTooltip="{{'WEB.BOOKING.MANUAL' | translate}}"
        (click)="onDownloadManual()"></fa-icon>


      <mat-menu #userMenu="matMenu">
        <div *ngIf="programType === 'BK'"
          class="menu-user-item"
          [routerLink]="['/booking/import']">
          <fa-icon icon="file-upload"
            class="icon-m mr-2">
          </fa-icon>
          <span>{{'WEB.BOOKING.IMPORT' | translate}}</span>
        </div>
        <div class="menu-user-item"
          [routerLink]="['/booking/export']">
          <fa-icon icon="file-download"
            class="icon-m mr-2"></fa-icon>
          <span>{{'WEB.BOOKING.EXPORT' | translate}}</span>
        </div>
      </mat-menu>

      <div class="lang-switch ml-2"
        *ngIf="languages.length > 1">
        <div *ngFor="let lang of languages; let last = last">
          <span class="text-uppercase cursor-pointer"
            [ngClass]="{'gray-medium': language === lang.code}"
            (click)="onLanguageChange(lang)">{{lang.code}}</span>
          <span *ngIf="!last"
            class="mx-3">|</span>
        </div>
      </div>
      <fa-icon icon="power-off"
        class="ml-3 icon-s cursor-pointer"
        (click)="onLogout()"></fa-icon>
    </div>
  </div>
</mat-toolbar>
<div class="container-fluid">
  <div class="row">
    <div class="paginator border-top col">
      <prada-select [options]="pageSizeOptions"
        [formControl]="pageSizeControl"
        [searchable]="false"
        (selectChange)="onPageSizeChange($event)"
        class="ml-auto mr-2 paginator__pageOptions"></prada-select>
      <i class="material-icons mr-2 cursor-pointer"
        [ngClass]="{'disabled': !hasPreviousPage}"
        (click)="onChangePagePrevious(pageIndex - 1)">
        keyboard_arrow_left
      </i>
      <i class="material-icons cursor-pointer"
        [ngClass]="{'disabled': !hasNextPage}"
        (click)="onChangePageNext(pageIndex + 1)">
        keyboard_arrow_right
      </i>
    </div>
  </div>
</div>
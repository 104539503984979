import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from '@web/core/services';
import { EBitfAuthState } from '@web/enums';

@Component({
  selector: 'prada-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
})
export class SignOutComponent implements OnInit {
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.authService.clearAuthData();
    this.authService.authState$.next(EBitfAuthState.TO_BE_LOGGED);
    setTimeout(() => {
      window.location.href = environment.signOutUrl;
    }, 1000);
  }
}

import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';

@Component({
  selector: 'prada-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepComponent implements OnInit {
  @Input() text: string;
  @Input() isOpen = false;
  @Input() isFirst = false;
  @Input() isDisabled = false;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {}

  onToggleIsOpen() {
    if (!this.isDisabled) {
      this.isOpen = !this.isOpen;
      this.cdr.detectChanges();
    }
  }

  setText(text: string) {
    this.text = text;
    this.cdr.detectChanges();
  }

  open() {
    this.isOpen = true;
    this.cdr.detectChanges();
  }

  close() {
    this.isOpen = false;
    this.cdr.detectChanges();
  }

  enable() {
    this.isDisabled = false;
    this.cdr.detectChanges();
  }

  disable() {
    this.isDisabled = true;
    this.cdr.detectChanges();
  }
}

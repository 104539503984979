import { EApiRequestPartKeys, EBitfApiRequestPartsNames } from '@enums';
import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

export class SearchApiRequestPart extends BitfApiRequestPart {
  constructor() {
    super(EApiRequestPartKeys.SEARCH, EBitfApiRequestPartsNames.QUERY);
  }

  build(): void {
    this.part = { firstName: this.data.searchKey };
  }
}

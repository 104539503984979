<div class="row">
  <div class="border-bottom py-3 w-100 d-flex flex-column col-24"
    [ngClass]="{'border-top': isFirst, 'bg-gray-xlight opacity-03': isDisabled}">
    <div class="d-flex align-items-center"
      (click)="onToggleIsOpen()"
      [ngClass]="{'cursor-pointer' : !isDisabled}">
      <i class="material-icons"
        *ngIf="!isOpen">keyboard_arrow_down</i>
      <i class="material-icons"
        *ngIf="isOpen">keyboard_arrow_up</i>
      <div class="f-size-16 font-weight-bold text-uppercase">{{text}}</div>
    </div>
    <div *ngIf="isOpen"
      class="mt-3">
      <ng-content></ng-content>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';

import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';
// tslint:disable-next-line:max-line-length
import { BitfMatSidenavService } from '@common/libs/bitforce/core/services/sidenav/material/bitf-mat-sidenav.service';

import { IBitfMenuItem, IBitfLanguage } from '@interfaces';
import { APP_VERSION, BUILD_DATE } from '@env/version';
import { AppSessionService, UiRoleManagerService, AuthService, StoreService } from '@services';
import { EBitfCloseEventStatus, ERoleActions, EBitfAuthState } from '@enums';
import { CONSTANTS } from '@web/constants';
import { BitfDynamicLocaleService } from '@common/libs/bitforce/core/services/locale/bitf-dynamic-locale.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'prada-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
})
export class SidenavMenuComponent implements OnInit {
  appVersion = APP_VERSION;
  appBuildAt = BUILD_DATE;

  languages = CONSTANTS.SUPPORTED_LANGUAGES;
  language: string;

  menuItems: Array<IBitfMenuItem> = [];

  constructor(
    public appSessionService: AppSessionService,
    private mSidenavService: BitfMatSidenavService,
    private uiRoleManagerService: UiRoleManagerService,
    private bitfDynamicLocaleService: BitfDynamicLocaleService,
    private authService: AuthService,
    private storeService: StoreService
  ) {}

  ngOnInit() {
    this.language = this.bitfDynamicLocaleService.locale.code.toUpperCase();
    this.authService.authState$.subscribe((authState: EBitfAuthState) => {
      if (authState === EBitfAuthState.LOGGED) {
        this.addMenuItems();
      }
    });
    if (this.storeService.store.user) {
      this.addMenuItems();
    }
  }
  private addMenuItems() {
    this.menuItems = [];
  }

  onLanguageChange(lang: IBitfLanguage) {
    this.language = lang.code;
    this.bitfDynamicLocaleService.setLocale(lang);
  }

  closeSidenav() {
    this.mSidenavService.close({ status: EBitfCloseEventStatus.CLOSE });
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { EBitfAuthState } from '@web/enums';
import { AuthService } from '@web/core/services';

@Component({
  selector: 'prada-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  authState: EBitfAuthState = EBitfAuthState.TO_BE_LOGGED;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.subscriptions.add(
      this.authService.authState$.subscribe((authState: EBitfAuthState) => {
        this.authState = authState;

        if (authState === EBitfAuthState.LOGGED) {
          // Present to the user the login progress
          setTimeout(() => {
            this.authService.handleRedirect();
          }, 2000);
        }
      })
    );
    this.authService.handleAuthentication();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

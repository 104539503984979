<div class="container-fluid">
  <div class="row mb-3">
    <span class="f-size-18 font-weight-bold col">{{'COMMON.LABEL.CONFIRM' | translate}}</span>
  </div>
</div>
<mat-dialog-content>
  <div class="container-fluid">
    <div class="row">
      <span class="col white-space-pre-line">{{data | translate}}</span>
    </div>
  </div>
</mat-dialog-content>
<div class="container-fluid">
  <div class="row mt-2">
    <div class="col-12">
      <button class="button button--secondary w-100"
        (click)="onCancel()">{{'COMMON.LABEL.CANCEL' | translate}}</button>
    </div>
    <div class="col-12">
      <button class="button button--primary w-100"
        (click)="onConfirm()">{{'COMMON.LABEL.CONFIRM' | translate}}</button>
    </div>
  </div>
</div>
import { Injectable, Injector } from '@angular/core';

import { BitfAuthGuard } from '@bitf/core/services/guards/bitf-auth.guard';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

import { BITF_CONFIGS } from '@configs';
import { UiRoleManagerService } from '..';
import { ERoleActions } from '@web/enums';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends BitfAuthGuard {
  constructor(protected injector: Injector, private uiRoleManagerService: UiRoleManagerService) {
    super(injector);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const bitfCanActivate = super.canActivate(next, state);
    if (typeof bitfCanActivate !== 'boolean') {
      // NOTE: Is an obserable
      return (bitfCanActivate as Observable<boolean>).pipe(
        switchMap(() => {
          return of(this.checkUserRoutePermission(next, state));
        })
      );
    }
    // NOTE: Is a boolean
    if (bitfCanActivate) {
      return this.checkUserRoutePermission(next, state);
    }
    return bitfCanActivate;
  }

  checkUserRoutePermission(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.storeService.store.user.isAuthorized) {
      this.router.navigate(['/unauthorized']);
      return false;
    }
    return true;
  }
}

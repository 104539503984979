import { Injectable, Injector } from '@angular/core';
import { BitfAdfsAuthService } from '@common/libs/bitforce/core/services/auth/adfs/bitf-adfs-auth.service';
import { BITF_CONFIGS } from '@common/configs/common-bitf.configs';
import { Store } from '../models';
import { EStoreActions } from '@web/enums';
import { StorageService } from '.';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfAdfsAuthService {
  tokenAlertTresholdMinutes = 5;
  constructor(protected injector: Injector) {
    super(injector);
  }

  handleRedirect() {
    this.router.navigate([BITF_CONFIGS.urls.homePageUrl]);
  }

  logout() {
    this.storeService.resetStore();
    this.storageService.clearData();
  }
}

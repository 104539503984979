import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { BitfDynamicLocaleService } from '@common/libs/bitforce/core/services/locale/bitf-dynamic-locale.service';
import { AppDateAdapter } from '@web/core/utils/format-datapicker.utils';

@Component({
  selector: 'prada-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
  ],
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  value: Date;
  disabled = false;

  constructor(
    private bitfDynamicLocaleService: BitfDynamicLocaleService,
    private dateAdapter: DateAdapter<any>
  ) {}

  ngOnInit() {
    this.bitfDynamicLocaleService.localeChange$.subscribe(locale => {
      this.dateAdapter.setLocale(locale.code);
    });
    this.dateAdapter.setLocale(this.bitfDynamicLocaleService.locale.code);
  }

  onDatePicked(data: MatDatepickerInputEvent<Date, any>) {
    this.writeValue(data.value);
  }

  onChange = (v: any) => {};

  onTouched = () => {};

  writeValue(v: any): void {
    // NOTE: this need when the value is changed by the form ( outside this component )
    this.value = v;
    this.onChange(v);
  }

  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

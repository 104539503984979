import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chunk',
})
export class ChunkPipe implements PipeTransform {
  transform(array: any[], size: number): any[] {
    const originalData = [...array];
    const data = [];
    while (originalData.length) {
      data.push(originalData.splice(0, size));
    }
    return data;
  }
}

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

export const CONSTANTS = {
  META_TAGS: {
    TITLE: {
      DEFAULT_TITLE: bitfToTranslate('COMMON.META_TAGS.DEFAULT_TITLE'),
      POSTFIX: bitfToTranslate('COMMON.META_TAGS.TITLE_POSTFIX'),
    },
    DESCRIPTION: bitfToTranslate('COMMON.META_TAGS.DESCRIPTION'),
  },
  SUPPORTED_LANGUAGES: [
    { code: 'IT', label: 'Italiano' },
    // { code: 'EN', label: 'English' },
  ],
  DEFAULT_LANGUAGE: { code: 'IT', label: 'Italiano' },
  LOCAL_STORAGE_VERSION: 1,
  INT_32_MAX: 2147483647,
  REGEXP_EMAIL_VALIDATION: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,10}$',
  BOOKING: 'BK',
  BOOKING_EVENTS: 'BE'
};

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPipe',
})
export class SearchPipe implements PipeTransform {
  transform(items: any[], search: string, ...keys: string[]): any {
    if (!search || keys.length === 0) {
      return items;
    }

    const result = [...items].filter(item => {
      let res = false;
      keys.forEach(key => {
        if (item.hasOwnProperty(key)) {
          res =
            res ||
            item[key]
              .toString()
              .toLowerCase()
              .includes(search.toString().toLowerCase());
        }
      });
      return res;
    });
    return result;
  }

  // private getValueFromProperty(o: {}, s: string) {
  //   s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  //   s = s.replace(/^\./, ''); // strip a leading dot
  //   const a = s.split('.');
  //   for (let i = 0, n = a.length; i < n; ++i) {
  //     const k = a[i];
  //     if (k in o) {
  //       o = o[k];
  //     } else {
  //       return;
  //     }
  //   }
  //   return o;
  // }
}

import { Injectable, Injector } from '@angular/core';

import { BitfApiService } from '../../api/bitf-api.service';
import { IBitfApiRequest } from '@interfaces';
import { environment } from '@env/environment';
import { BitfTryCatch } from '@bitf/core/decorators/bitf-try-catch.decorator';

@Injectable({
  providedIn: 'root',
})
export abstract class BitfAdfsService extends BitfApiService {
  constructor(protected injector: Injector) {
    super('adfs', injector);
  }

  @BitfTryCatch()
  generateToken(code: string) {
    return this.request({
      method: 'POST',
      path: '/auth',
      queryParams: {
        code,
        clientId: environment['clientId'],
        redirectUri: environment['adfsRedirectUri'],
      },
    } as IBitfApiRequest);
  }
}

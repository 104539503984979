import {
  BitfMockDbPathBuilder,
  BitfMockDbRequestMapper,
  BitfMockDbResponseMapper,
  BitfMockDbEnvelopeMapper,
} from '@bitf/core/parsers/mock-db-parser';
import { AppPathBuilder, AppRequestMapper, AppResponseMapper, AppEnvelopeMapper } from '@parsers';
import { IBitfParserStrategy } from '@interfaces';

export const BITF_CONFIGS: any = {
  authService: {
    tokenQueryParmName: 'code',
    localStorageTokenName: 'authToken',
    tokenAlertTresholdMinutes: 5,
  },
  apiErrorsInterceptorService: { interceptHttpResponsesWithCode: [401, '4XX', '5XX'] },
  parsers: {
    defaultParser: 'appParser',
    parserStrategies: {
      appParser: {
        pathBuilder: new AppPathBuilder(),
        requestMapper: new AppRequestMapper(),
        responseMapper: new AppResponseMapper(),
        envelopeMapper: new AppEnvelopeMapper(),
      } as IBitfParserStrategy,
      mockDbParser: {
        pathBuilder: new BitfMockDbPathBuilder(),
        requestMapper: new BitfMockDbRequestMapper(),
        responseMapper: new BitfMockDbResponseMapper(),
        envelopeMapper: new BitfMockDbEnvelopeMapper(),
      } as IBitfParserStrategy,
    },
  },
};

import { Component, OnInit, Injector, TemplateRef, ContentChild } from '@angular/core';
import { SuperHeaderComponent } from '@web/shared/components/super-header.component';
import { AuthService, StoreService } from '@services';
import { environment } from 'environments/environment';
import { CONSTANTS } from '@common/constants/common.constants';
import { BitfDynamicLocaleService } from '@common/libs/bitforce/core/services/locale/bitf-dynamic-locale.service';
import { IBitfLanguage } from '@web/interfaces';
import { IBitfRoleManagerConfig } from '@common/libs/bitforce/directives/ui-role-manager/bitf-ui-role-manager.directive';
import { ERoleActions, ERoleMode } from '@web/enums';

@Component({
  selector: 'prada-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends SuperHeaderComponent {
  constructor(
    public injector: Injector,
    public storeService: StoreService,
    private bitfDynamicLocaleService: BitfDynamicLocaleService,
    private authService: AuthService
  ) {
    super(injector);
  }
  brands: any[] = [];

  language: string;
  languages = CONSTANTS.SUPPORTED_LANGUAGES;

  isMeetUserRoleConfig: IBitfRoleManagerConfig = {
    action: ERoleActions.IS_MEET_USER,
    mode: ERoleMode.HIDDEN,
  };

  isMeetUserReverseRoleConfig: IBitfRoleManagerConfig = {
    action: ERoleActions.IS_MEET_USER,
    mode: ERoleMode.HIDDEN,
    reverse: true,
  };
  programType: string = environment.programType;
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this.language = this.bitfDynamicLocaleService.locale.code.toUpperCase();

    if (!this.isLanguageSupported(this.language)) {
      this.language = this.bitfDynamicLocaleService.defaultLocale.code.toUpperCase();
      this.bitfDynamicLocaleService.setLocale(CONSTANTS.DEFAULT_LANGUAGE);
    }
  }

  onLanguageChange(lang: IBitfLanguage) {
    this.language = lang.code;
    this.bitfDynamicLocaleService.setLocale(lang);
  }

  onGoToHome() {
    window.location.href = environment.signOutUrl;
  }

  onDownloadManual() {
    window.open(environment.manualUrl);
  }

  onLogout() {
    this.authService.signOut();
  }

  isLanguageSupported(lang: string) {
    for (var i = 0; i < this.languages.length; i++) {
      if (this.languages[i].code.toUpperCase() == lang) {
        return true;
      }
    }
    return false;
  }
}

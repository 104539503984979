export enum ERoleMode {
  READ_ONLY = 'READ_ONLY',
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED',
}

export enum ERoles {
  MASTER = 'MASTER',
}

export enum ERoleActions {
  VIEW_LOG = 'VIEW_LOG',
  IS_MEET_USER = 'IS_MEET_USER',
}

import { Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BitfAuthService } from '../bitf-auth.service';
import { BitfAdfsService } from './bitf-adfs.service';
import { IBitfAdfsTokenMetadata, IBitfAdfsLogin } from './bitf-adfs-auth.interface';

import { EBitfAuthState } from '@enums';
import { environment } from '@env/environment';
import { User } from '@models';
import { BitfTryCatch } from '@bitf/core/decorators/bitf-try-catch.decorator';
import { IBitfApiResponse } from '@interfaces';
import { HttpClient } from '@angular/common/http';
export abstract class BitfAdfsAuthService extends BitfAuthService<IBitfAdfsLogin, IBitfAdfsTokenMetadata> {
  protected activatedRoute: ActivatedRoute;
  protected bitfAdfsService: BitfAdfsService;
  private httpClient: HttpClient;

  constructor(protected injector: Injector) {
    super(injector);
    // Ts lint bug: it gets the wrong injector interface for BitfAdfsService
    // tslint:disable-next-line
    this.bitfAdfsService = injector.get(BitfAdfsService);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.httpClient = injector.get(HttpClient);
  }

  @BitfTryCatch()
  handleAuthentication(queryParamVarName = 'code') {
    const encodedToken = this.activatedRoute.snapshot.queryParamMap.get(queryParamVarName);
    if (encodedToken) {
      this.authState$.next(EBitfAuthState.LOGIN_IN_PROGRESS);
      this.generateToken(encodedToken);
    } else if (super.isTokenValid()) {
      this.usersService.getMe().subscribe(
        (response: IBitfApiResponse<User>) => {
          this.setUser(response.content);
          this.authState$.next(EBitfAuthState.LOGGED);
        },
        () => {
          this.onLoginError();
        }
      );
    } else {
      this.authState$.next(EBitfAuthState.TO_BE_LOGGED);
      window.location.href = environment['adfsUrl'];
    }
  }

  @BitfTryCatch()
  private generateToken(code: string) {
    this.bitfAdfsService.generateToken(code).subscribe(
      (response: IBitfApiResponse<any>) => {
        const content = response.content || response.originalBody;
        if (content.error) {
          this.onLoginError(` :${content.error} - ${content.error_description}`);
        } else {
          super.signIn({
            encodedToken: content.access_token,
            expiresIn: content.expires_in,
          });
          this.usersService.getMe().subscribe(
            (meResponse: IBitfApiResponse<User>) => {
              this.setUser(meResponse.content);
              this.authState$.next(EBitfAuthState.LOGGED);
            },
            () => {
              this.onLoginError();
            }
          );
        }
      },
      () => {
        this.onLoginError();
      }
    );
  }

  @BitfTryCatch()
  decodeToken(loginResponse: IBitfAdfsLogin): IBitfAdfsTokenMetadata {
    if (!loginResponse) {
      return undefined;
    }
    const tokenParts = loginResponse.encodedToken.split('.');
    const decodedToken = JSON.parse(atob(tokenParts[1]));
    const keyToReplace = 'http://schemas.microsoft.com/LiveID/Federation/2008/05/ImmutableID';

    Object.assign(decodedToken, {
      token: loginResponse.encodedToken,
      expiresAt: decodedToken.exp * 1000,
      expiresIn: loginResponse.expiresIn * 1000,
      immutableId: decodedToken[keyToReplace],
      iat: decodedToken.iat * 1000,
      exp: decodedToken.exp * 1000,
    });
    delete decodedToken[keyToReplace];
    return decodedToken as IBitfAdfsTokenMetadata;
  }

  @BitfTryCatch()
  async renewToken(): Promise<IBitfAdfsLogin> {
    if (!this.authTokenMetaData.refreshToken) {
      return Promise.reject('No refresh token found');
    }
    return new Promise((success, error) => {
      const body = {
        clientId: environment['clientId'],
        redirectUri: environment['adfsRedirectUri'],
        refresh_token: this.authTokenMetaData.refreshToken,
      };
      this.httpClient
        .post(`${environment.apiUrl}adfs/token`, null, {
          params: body,
        })
        .subscribe(
          (response: any) => {
            // NOTE: we've to keep the refresh token
            response.encodedToken = response.access_token;
            response.refreshToken = response.refresh_token;
            this.signIn(response);
            success(response);
          },
          e => {
            error(e);
          }
        );
    });
  }
}

<div class="main-menu-container zindex-modal border-right"
  [ngClass]="isOpen ? 'main-menu-open' : 'main-menu-close'">

  <div class="h-100 d-flex flex-column w-100">
    <div class="flex-grow-1 d-flex flex-column">
      <div class="d-flex justify-content-start">
        <button type="button"
          mat-button
          (click)="toggleSidenav()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div class="flex-grow-1">
        <mat-nav-list>
          <mat-list-item *ngFor="let item of menuItems"
            [routerLink]="item.url">
            <mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
            <h4 mat-line>{{ item.label | translate }}</h4>
          </mat-list-item>
        </mat-nav-list>
      </div>
    </div>
    <mat-nav-list>
      <mat-list-item [matMenuTriggerFor]="langMenu">
        <mat-icon mat-list-icon>translate</mat-icon>
        <h4 mat-line>{{'COMMON.LABEL.LANGUAGE' | translate}}</h4>
      </mat-list-item>
    </mat-nav-list>
    <mat-menu #langMenu="matMenu"
      class="ml-3">
      <button mat-menu-item
        *ngFor="let lang of languages"
        class="mat-menu-item--m"
        [ngClass]="{'font-weight-bold': language === lang.code}"
        (click)="onLanguageChange(lang)">
        {{lang.label}}
      </button>
    </mat-menu>
    <mat-divider></mat-divider>
    <div class="text-center mat-small my-2">
      v{{appVersion}} <span *ngIf="isOpen">- {{appBuildAt | date}}</span>
    </div>
  </div>
</div>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'prada-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit {
  @Input() pageIndex: number;
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[] = [10, 20, 30];
  @Input() hasNextPage: boolean;
  @Input() hasPreviousPage: boolean;
  @Output() pageChange = new EventEmitter<PageEvent>();

  pageSizeControl = new FormControl();
  constructor() {}

  ngOnInit() {
    this.pageSizeControl.setValue(this.pageSize ? this.pageSize : this.pageSizeOptions[0]);
  }

  onChangePagePrevious(pageIndex: number) {
    if (this.hasPreviousPage) {
      return this.changePage(pageIndex);
    }
  }

  onChangePageNext(pageIndex: number) {
    if (this.hasNextPage) {
      return this.changePage(pageIndex);
    }
  }

  private changePage(pageIndex: number) {
    const previous = this.pageIndex;
    this.pageIndex = pageIndex;
    this.pageChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      previousPageIndex: previous,
    } as PageEvent);
  }

  onPageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    this.pageChange.emit({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      previousPageIndex: this.pageIndex,
    } as PageEvent);
  }
}

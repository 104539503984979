import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';

import { DesktopLayoutComponent } from './core/components/desktop-layout/desktop-layout.component';
// import { AuthGuard } from '@common/core/services/guards';

import { NotFoundComponent } from './core/components/not-found/not-found.component';

// import { DemoComponent } from './modules/demo/demo.component';
import { AuthGuard } from '@common/core/services/guards';
import { SignInComponent } from './core/components/sign-in/sign-in.component';
import { SignOutComponent } from './core/components/sign-out/sign-out.component';
import { SignInErrorComponent } from './core/components/sign-in-error/sign-in-error.component';

// const routes: Routes = [
//   { path: '', redirectTo: 'software', pathMatch: 'full' },
//   { path: 'm', redirectTo: 'software', pathMatch: 'full' },
//   {
//     path: 'software',
//     component: SoftwareComponent,
//     // canActivate: [AuthGuard],
//   },
//   {
//     path: 'users',
//     component: UsersComponent,
//     // canActivate: [AuthGuard],
//   },
// ];

const sharedRoutes: Routes = [
  { path: '', redirectTo: 'booking', pathMatch: 'full' },
  {
    path: 'signin',
    component: SignInComponent,
  },
  {
    path: 'signout',
    component: SignOutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'unauthorized',
    component: SignInErrorComponent,
  },
  {
    path: 'booking',
    loadChildren: () => import('./modules/booking/booking.module').then(m => m.BookingModule),
    canActivateChild: [AuthGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

const routes: Routes = [
  {
    path: '',
    component: DesktopLayoutComponent,
    children: [...sharedRoutes],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }

<div class="h-100 d-flex justify-content-center align-items-center p-3">
  <mat-card class="col-24 col-sm-auto mat-elevation-z5">
    <mat-card-content class="d-flex flex-column justify-content-around align-items-center p-3">
      <img src="assets/images/logo_prada_group.png"
        alt="Prada"
        height="100px">
      <h1 class="mt-5">{{'COMMON.LABEL.SIGN_IN_ERROR_TITLE' | translate}}</h1>
      <p class="my-4">{{'COMMON.LABEL.SIGN_IN_ERROR_MESSAGE' | translate}}</p>
    </mat-card-content>
  </mat-card>
</div>
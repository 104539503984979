import { Component, OnInit } from '@angular/core';

import { AppSessionService } from '@services';
import { environment } from '@env/environment';

@Component({
  selector: 'prada-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  init = false;
  constructor(private appSessionService: AppSessionService) {}
  async ngOnInit() {
    await this.appSessionService.init();
    this.init = true;
    console.log('ENV', environment);
  }
}

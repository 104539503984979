import { Injectable } from '@angular/core';

import { BitfStorageService } from '@bitf/services/storage/bitf-storage.service';

import { Store } from '@models';

@Injectable({
  providedIn: 'root',
})
export class StoreStorageService extends BitfStorageService<Store> {
  constructor() {
    super({
      nativeStorage: sessionStorage,
      storageKey: 'store-storage',
      StorageClass: Store,
      initialData: { prop1: 'Initial store storage value' },
    });
  }
}

<mat-dialog-content>
    <div class="container-fluid">
        <div class="row">
            <span class="col white-space-pre-line"
                [innerHTML]="data | translate"></span>
        </div>
    </div>
</mat-dialog-content>
<div class="container-fluid">
    <div class="row mt-2">
        <div class="col-24 d-flex justify-content-center">
            <button class="button button--primary"
                (click)="onOk()">{{'BITF.LABEL.OK' | translate}}</button>
        </div>
    </div>
</div>
import { OnInit, Input, Output, EventEmitter, Directive } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

import { ApiCallStateService } from '@services';
import { EApiCallStateNames, EApiRequestPartKeys } from '@enums';
import { IBitfApiPagination, IBitfPaginatorOptions } from '@interfaces';

@Directive()
export class BitfPaginatorComponent implements OnInit {
  @Input()
  apiCallStateName: EApiCallStateNames;
  @Input()
  pagination: IBitfApiPagination;
  @Input()
  options: IBitfPaginatorOptions;
  @Input()
  pageSizeOptions = [1, 5, 15];
  // NOTE: mat paginator first page starts with 0 index
  @Input()
  pageIndexOffset = 0;
  @Output()
  pageChange: EventEmitter<PageEvent> = new EventEmitter();
  requestPart: BitfApiRequestPart;
  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit() {
    if (this.apiCallStateName) {
      this.requestPart = this.apiCallStateService.getRequestPart(
        this.apiCallStateName,
        EApiRequestPartKeys.PAGINATION
      );
    }
  }

  onPageChange(event) {
    if (this.apiCallStateName) {
      this.apiCallStateService.setStore(() => {
        this.requestPart.data = {
          page: event.pageIndex + this.pageIndexOffset,
          size: event.pageSize,
        };
      }, this.apiCallStateName);
    }

    this.pageChange.emit(event);
  }
}

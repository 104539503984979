import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@common/shared/common-shared.module';
import { MATERIAL_MODULES, CDK_MODULES } from './material-modules';

import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';
// tslint:disable-next-line:max-line-length
import { BitfMatPaginatorComponent } from '@bitf/core/components/ui/paginator/material/bitf-mat-paginator.component';
// tslint:disable-next-line:max-line-length
import { BitfUiRoleManagerDirective } from '@common/libs/bitforce/directives/ui-role-manager/bitf-ui-role-manager.directive';

import { SearchComponent } from './components/search/search.component';
import { HeaderComponent } from './components/header/header.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { FormErrorDirective } from './directives/form-error/form-error.directive';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { SearchPipe } from './pipes/search.pipe';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { GroupByPipe } from './pipes/group-by.pipe';
import { StepComponent } from './components/step/step.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SelectComponent } from './components/select/select.component';
import { AutofocusDirective } from './directives/autofocus/autofocus.directive';
import { FormSubmitDirective } from './directives/form-submit/form-submit.directive';
import { TabListComponent } from './components/tab-list/tab-list.component';
import { TabComponent } from './components/tab/tab.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { SwitchComponent } from './components/switch/switch.component';
import { BitfClipboardDirective } from '@common/libs/bitforce/directives/bitf-clipbard.directive';
import { ChunkPipe } from './pipes/chunk.pipe';
import { MinuteSecondsPipe } from './pipes/minute-seconds.pipe';
import { NotificationComponent } from './components/notification/notification.component';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { OkDialogComponent } from './components/ok-dialog/ok-dialog.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { BitfDatePipe } from '@common/libs/bitforce/pipes/bitf-date.pipe';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { NoCopyPasteDirective } from './directives/no-copy-paste/no-copy-paste.directive';

const SHARED_MODULES = [CommonSharedModule, RouterModule, MATERIAL_MODULES, CDK_MODULES];
const SHARED_COMPONENTS = [
  BitfLoaderComponent,
  SearchComponent,
  BitfMatPaginatorComponent,
  HeaderComponent,
  HeaderComponent,
  ConfirmDialogComponent,
  OkDialogComponent,
  InfiniteScrollComponent,
  StepComponent,
  SelectComponent,
  TabListComponent,
  TabComponent,
  PaginatorComponent,
  SwitchComponent,
  NotificationComponent,
  DatePickerComponent,
];
const SHARED_DIRECTIVES = [
  FormErrorDirective,
  TooltipDirective,
  AutofocusDirective,
  FormSubmitDirective,
  BitfClipboardDirective,
  BitfUiRoleManagerDirective,
  NoCopyPasteDirective
];
const SHARED_PIPES = [SearchPipe, GroupByPipe, OrderByPipe, ChunkPipe, MinuteSecondsPipe, BitfDatePipe];
@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...SHARED_PIPES,
        {
          provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
          useValue: { duration: 5000, horizontalPosition: 'right', panelClass: ['snackbar'] },
        },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
      ],
    };
  }
  constructor(private library: FaIconLibrary) {
    library.addIconPacks(fas, far);
  }
}

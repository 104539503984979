import { Component, OnInit, Input, ContentChild, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'prada-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit {
  @Input() header: string;
  @Input() active = false;
  constructor() {}

  ngOnInit() {}
}

<div class="container-fluid">
    <div class="row">
        <div class="col-auto p-0 d-flex align-items-center">
            <fa-icon icon="exclamation-circle"
                class="red icon-xl"
                *ngIf="data.notificationType === notificationType.ERROR"></fa-icon>
            <fa-icon icon="check"
                class="green icon-xl"
                *ngIf="data.notificationType === notificationType.SUCCESS"></fa-icon>
            <fa-icon icon="exclamation-triangle"
                class="yellow icon-xl"
                *ngIf="data.notificationType === notificationType.WARNING"></fa-icon>
            <fa-icon icon="info-circle"
                class="blue icon-xl"
                *ngIf="data.notificationType === notificationType.INFO"></fa-icon>
        </div>
        <div class="col">
            <div class="font-weight-bold mb-1">
                <span
                    *ngIf="data.notificationType === notificationType.SUCCESS">{{'COMMON.NOTIFICATION.SUCCESS'| translate}}</span>
                <span
                    *ngIf="data.notificationType === notificationType.ERROR">{{'COMMON.NOTIFICATION.ERROR'| translate}}</span>
                <span
                    *ngIf="data.notificationType === notificationType.WARNING">{{'COMMON.NOTIFICATION.WARNING'| translate}}</span>
                <span
                    *ngIf="data.notificationType === notificationType.INFO">{{'COMMON.NOTIFICATION.INFO'| translate}}</span>
            </div>
            <div>{{data.message | translate}}</div>
        </div>
        <div class="col-auto col--close p-0">
            <span class="cursor-pointer font-weight-bold icon-xxxl"
                (click)="onClose()">&times;</span>
        </div>
    </div>
</div>
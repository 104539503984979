import { BitfPaginationApiRequestPart } from '@bitf/core/api-call-state/bitf-pagination-api-request-part';

import { EApiCallStateNames, EApiRequestPartKeys } from '@enums';
import { IBitfApiCallState } from '@interfaces';
import { DefaultApiCallStateMapper } from './default-api-call-state.mapper';
import { SearchApiRequestPart } from '@web/shared/components/search/search-api-request-part.builder';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.DEMO,
    apiRequest: {
      // NOTE passing page and size from here is still valid if BitfPaginationApiRequestPart is not used
      // page: 1,
      // size: 2,
      sorting: [{ property: 'firstName', direction: 'ASC' }],
    },
    requestParts: [
      new BitfPaginationApiRequestPart(EApiRequestPartKeys.PAGINATION, { page: 1, size: 2 }),
      new SearchApiRequestPart(),
    ],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];

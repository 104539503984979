import { SuperModel } from './super-model.model';

export class User extends SuperModel {
  name: string;
  surname: string;
  roles: string[];
  cid: string;
  email: string;
  isAuthorized: boolean;
  completeName: string;
  ticketsThreshold: number;
  invitesThreshold: number;
  canExceedTicketsThreshold: boolean;
  canInviteVip: boolean;
  thresholdByCategory: boolean;

  constructor(data: any = {}) {
    super(data);
    if (this.name && this.surname) {
      this.completeName = `${this.name} ${this.surname}`;
    }
    if (this.email && this.email.includes('@nomail.pradagroup.net')) {
      this.email = null;
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      surname: this.surname,
      cid: this.cid,
      completeName: this.completeName,
      isAuthorized: this.isAuthorized,
      email: this.email,
      roles: this.roles,
      ticketsThreshold: this.ticketsThreshold,
      invitesThreshold: this.invitesThreshold,
      canExceedTicketsThreshold: this.canExceedTicketsThreshold,
      canInviteVip: this.canInviteVip,
      thresholdByCategory: this.thresholdByCategory
    };
  }
}

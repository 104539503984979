import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { EBitfUiMessageType } from '@web/enums';

@Component({
  selector: 'prada-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  notificationType = EBitfUiMessageType;
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<NotificationComponent>
  ) {}

  ngOnInit() {}

  onClose() {
    this.snackBarRef.dismiss();
  }
}

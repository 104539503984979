import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

// tslint:disable-next-line:max-line-length
import { BitfMatToastMessageService } from '@bitf/services/toast-messages/material/bitf-mat-toast-message.service';
import { IBitfToastMessagesService } from '@interfaces';
import { EBitfUiMessageType } from '@web/enums';
import { NotificationComponent } from '@web/shared/components/notification/notification.component';

@Injectable({
  providedIn: 'root',
})
export class ToastMessagesService extends BitfMatToastMessageService implements IBitfToastMessagesService {
  constructor(protected matSnackBar: MatSnackBar) {
    super(matSnackBar);
  }

  public showSuccess(message: string) {
    this.showFromComponent(message, EBitfUiMessageType.SUCCESS);
  }
  public showError(message: string) {
    this.showFromComponent(message, EBitfUiMessageType.ERROR);
  }
  public showWarning(message: string) {
    this.showFromComponent(message, EBitfUiMessageType.WARNING);
  }
  public showInfo(message: string) {
    this.showFromComponent(message, EBitfUiMessageType.INFO);
  }

  private showFromComponent(message: string, notificationType: EBitfUiMessageType) {
    this.matSnackBar.openFromComponent(NotificationComponent, {
      data: { notificationType: notificationType, message: message },
    });
  }
}

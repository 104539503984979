import {
  Component,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
  NgZone,
  OnChanges,
  SimpleChanges,
  ElementRef,
  forwardRef,
  HostBinding,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import {
  debounceTime,
  filter,
  takeUntil,
  distinctUntilChanged,
  tap,
  map,
  switchMap,
  mergeMap,
  finalize,
} from 'rxjs/operators';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subscription, Observable, iif, of, EMPTY } from 'rxjs';
import { createPopper } from '@popperjs/core';
@Component({
  selector: 'prada-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
})
export class SelectComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() label = 'label';
  @Input() key = 'key';
  @Input() options = [];
  @Input() searchable = true;
  @Input() multiple = false;
  @Input() separator = ', ';
  @Input() visibleOptions = 6;
  @Input() disabled = false;
  @Output() change = new EventEmitter();
  //  @Output() searchChange = new EventEmitter<string>();
  @Output() closed = new EventEmitter();
  search = new FormControl();
  originalOptions = [];
  private view: EmbeddedViewRef<any>;
  private popperRef: any;
  private subscription = new Subscription();
  private value: any;
  @HostBinding('class.disabled')
  public get isDisabled(): boolean {
    return this.disabled;
  }
  constructor(private vcr: ViewContainerRef, private zone: NgZone) {}
  ngOnInit() {
    this.subscription = this.search.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        // tap(v => this.searchChange.emit(v)),
        map(search => {
          if (search) {
            search = search
              .toString()
              .trim()
              .toLowerCase();
            this.options = this.originalOptions.filter(option =>
              option[this.label]
                .toString()
                .trim()
                .toLowerCase()
                .includes(search)
            );
          } else {
            this.options = [...this.originalOptions];
          }
        })
      )
      .subscribe(() => {});
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.options && changes.options.currentValue) {
      const newOptions = this.normalizeOptions(changes.options.currentValue);
      this.originalOptions = [...newOptions];
      this.options = [...newOptions];
    }
  }
  private normalizeOptions(options: any[]) {
    return options.map(x => {
      if (typeof x === 'object') {
        return x;
      } else {
        return { [this.key]: x, [this.label]: x };
      }
    });
  }
  get isOpen() {
    return this.popperRef;
  }
  get currentTextValue() {
    const v = Array.isArray(this.value) ? this.value : [this.value];
    return this.originalOptions
      .filter(x => v.includes(x[this.key]))
      .map(x => x[this.label])
      .join(this.separator);
  }
  open(dropdownTpl: TemplateRef<any>, origin: HTMLElement) {
    if (this.isOpen) {
      this.close();
      return;
    }
    if (!this.disabled) {
      this.view = this.vcr.createEmbeddedView(dropdownTpl);
      const dropdown = this.view.rootNodes[0];
      document.body.appendChild(dropdown);
      // NOTE: 2 is the border of the component
      dropdown.style.width = `${origin.offsetWidth + 2}px`;
      this.zone.runOutsideAngular(() => {
        this.popperRef = createPopper(origin, dropdown, {
          placement: 'bottom',
        });
      });
      this.handleClickOutside();
    }
  }
  close() {
    this.closed.emit();
    this.popperRef.destroy();
    this.view.destroy();
    // If you don't emit the event, the current search will eat up all of the original options
    // leaving you with, basically, the currently shown options as the list of items
    // available on the next click.
    // this.search.patchValue(null, { emitEvent: false });
    this.search.patchValue(null, { emitEvent: true });
    this.view = null;
    this.popperRef = null;
  }
  select(event: Event, option: any) {
    event.stopPropagation();
    if (this.multiple) {
      if (!this.value) {
        this.value = [];
      }
      const index = (this.value as any[]).indexOf(option[this.key]);
      if (index > -1) {
        (this.value as any[]).splice(index, 1);
      } else {
        (this.value as any[]).push(option[this.key]);
      }
    } else {
      this.value = option[this.key];
    }
    this.writeValue(this.value);
    this.onTouched();
    this.change.emit(this.value);

    if (!this.multiple) {
      this.close();
    }
  }
  private handleClickOutside() {
    fromEvent(document, 'click')
      .pipe(
        filter(({ target }) => {
          if (!this.popperRef) {
            return false;
          }
          const trigger = this.popperRef.state.elements.reference as HTMLElement;
          return (
            (target as HTMLElement).id !== 'open' &&
            (target as HTMLElement).id !== 'close' &&
            trigger.contains(target as HTMLElement) === false
          );
        }),
        takeUntil(this.closed)
      )
      .subscribe(value => {
        this.close();
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  onChange = (v: any) => {};
  onTouched = () => {};
  writeValue(v: any): void {
    // NOTE: this need when the value is changed by the form ( outside this component )
    this.value = v;
    this.onChange(v);
  }
  registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.isOpen && isDisabled) {
      this.close();
    }
  }
}

import { Directive, ElementRef, OnDestroy, OnInit, Renderer2, Optional, Host } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { Subscription, merge, Observable, EMPTY } from 'rxjs';
import { FormSubmitDirective } from '../form-submit/form-submit.directive';

@Directive({
  selector: '[pradaFormError]',
})
export class FormErrorDirective implements OnInit, OnDestroy {
  private statusChangeSubscription: Subscription;
  submit$: Observable<Event>;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private formControl: NgControl,
    @Optional() @Host() private form: FormSubmitDirective
  ) {
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
  }

  ngOnInit(): void {
    this.statusChangeSubscription = merge(this.formControl.statusChanges, this.submit$).subscribe(x => {
      if (this.formControl.status === 'INVALID') {
        this.renderer.addClass(this.elRef.nativeElement, 'error-form');
      } else {
        this.renderer.removeClass(this.elRef.nativeElement, 'error-form');
      }
    });
  }

  ngOnDestroy(): void {
    this.statusChangeSubscription.unsubscribe();
  }
}

const baseApiUrl = 'https://wsp.pradagroup.net/pradabooking-ws';
const apiUrl = `${baseApiUrl}/api/`;
const clientId = '3e2b53c9-782e-6348-b4ba-919452c90dfc';
const host = 'https://booking.pradagroup.net/';
const adfsRedirectUri = `${host}signin`;

export const environment = {
  production: true,
  name: 'production',
  appName: 'Booking',
  storageVersion: '1.0.0',
  baseApiUrl,
  apiUrl,
  authInterceptorUrls: [apiUrl],
  mockApiUrl: 'https://localhost:3002/',
  sockets: { url: '' },
  signOutUrl: 'http://my-prada.pradagroup.com/',
  manualUrl: 'assets/PrenotazioneIngressoVenditaSpeciale.pdf',
  httpRetryConfig: {
    enabled: true,
    verbs: {
      get: { enabled: true, timeout: 6000, maxRetries: 2, retryForStatusesCodes: [500] },
      post: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      patch: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      put: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
      delete: { enabled: false, timeout: null, maxRetries: 1, retryForStatusesCodes: [500] },
    },
  },
  loggerConfig: {
    enabled: true,
    maxLogRentention: 20,
    logHttpErrorsWithStatusesCodes: ['0', '4XX', '5XX'],
    logDecoratedExceptions: true,
    logExceptions: true,
    saveLogMessagesInterval: 30000,
    logUrl: 'https://logs.logdna.com/logs/ingest?apikey=bf8cf553dba4a1e882fa09491aaed2c7',
    sendLogs: false,
    sendRequestBody: false,
    sendQueryParams: false,
  },
  tagManager: {
    enabled: false,
    apiKey: '',
  },
  registerServiceWorker: false,
  clientId,
  adfsRedirectUri,
  // tslint:disable-next-line:max-line-length
  adfsUrl: `https://sts.pradagroup.net/adfs/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${adfsRedirectUri}&resource=${baseApiUrl}`,
  currentEventCode: '1D7D6E10-7373-43EA-8F8A-1AEC89ED0FB7',
  programType: 'BK' //BE(Booking_Events) - BK(Booking)
};
